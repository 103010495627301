<template>
  <div class="add-shipment">
    <div class="max-width">
      <v-stepper class="mt-14" v-model="step">
        <!-- steps head -->
        <v-stepper-header>
          <v-stepper-step :editable="false" :complete="step > 1" step="1">
            <span class="font-22"> {{ $t("addLink") }}</span>
          </v-stepper-step>

          <v-stepper-step step="2">
            <span class="font-22"> {{ $t("reviewProduct") }}</span>
          </v-stepper-step>

          <v-stepper-step step="3">
            <span class="font-22"> {{ $t("enterAddressSend") }}</span>
          </v-stepper-step>
        </v-stepper-header>

        <!-- steps content -->
        <v-stepper-items>
          <!-- enter product -->
          <v-stepper-content step="1"
            ><add-link @next="stepOneHandler" :loadingStepOne.sync="loadingStepOne" ></add-link>
          </v-stepper-content>

          <!-- review product -->
          <v-stepper-content step="2"
            ><view-details-step
              @next="step = 3"
              @back="step = 1"
              :product="step1Obj"
            ></view-details-step>
          </v-stepper-content>

          <!-- address & send product -->

          <v-stepper-content step="3"
            ><enter-address-step
              @submit="addProductHandler"
              @back="step = 2"
            ></enter-address-step>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>

    <!-- new product dialog -->
    <v-dialog v-model="addDialog" max-width="400" content-class="white">
      <add-shipment
        :title="$t('productSuccessfullyAdded')"
        :goTo="$t('goToMyProducts')"
        @close="$router.push('/profile/products')"
      ></add-shipment>
    </v-dialog>
  </div>
</template>

<script>
import AddShipment from "../../../components/dialogs/AddShipment.vue";
import EnterAddressStep from "../components/EnterAddress.vue";
import ViewDetailsStep from "../components/ViewDetails";
import AddLink from "./AddLink";
export default {
  components: {
    EnterAddressStep,
    ViewDetailsStep,
    AddLink,
    AddShipment,
  },
  data: () => ({
    addDialog: false,
    step: 1,
    obj: { images: [] },
    step1Obj: {},
    step2Obj: {},
    loadingStepOne:false
  }),
  methods: {
    async stepOneHandler(link) {
      let formDataProduct = new FormData();
      formDataProduct.append("url", link);
      this.loadingStepOne=true
      await this.$axios.post("productOnline", formDataProduct).then((res) => {
        console.log(res);

        if (res.data.success) {
          this.step1Obj = JSON.parse(JSON.stringify(res.data.data));
          this.loadingStepOne=false
          this.step = 2;
          this.$store.dispatch("showSnack", {
            text: res.data.message,
            color: "success",
          });
        } else {
          this.$store.dispatch("showSnack", {
            text: res.data.message,
            color: "error",
          });
        }
      });
    },
    stepTwoHandler(value) {
      this.step2Obj = { ...value };
      this.handleSubmit();
    },
    async addProductHandler(value) {
      let formDataBuy = new FormData();
      formDataBuy.append("product_id", this.step1Obj.id);
      formDataBuy.append("shipment_date", value.shipment_date);
      formDataBuy.append("country", value.country);
      formDataBuy.append("city", value.city);
      formDataBuy.append("address", value.address);
      formDataBuy.append("category_id", 1);
      formDataBuy.append("latitude", value.latitude_destination);
      formDataBuy.append("longitude", value.longitude_destination);
      await this.$axios.post("buyForMe", formDataBuy).then((res) => {
        if (res.data.success) {
          this.$store.dispatch("showSnack", {
            text: res.data.message,
            color: "success",
          });
          this.$router.push("/profile/shipments");
        } else {
          this.$store.dispatch("showSnack", {
            text: res.data.message,
            color: "error",
          });
        }
      });
    },
    closeHandler() {
      this.addDialog = false;
      this.step = 1;
    },
  },
};
</script>

<style lang="scss">

.add-shipment {
  .v-stepper {
    background: transparent !important;
    box-shadow: none !important;

    &__header {
      box-shadow: none !important;
      background: transparent !important;
      max-width: max-content;
      margin: auto;
      height: min-content !important;
    }
    &__step {
      border: 1px solid $grey;
      border-radius: 15px;
      padding: 8px 40px;
      transition: 0.3s;
      margin: 0px 10px;

      &--active {
        border: 1px solid $primary;
        background: white;
      }
    }
  }
  @media (max-width: 960px) {
    .v-stepper {
      &__step {
        padding: 8px 40px;
        span {
          margin-left: 5px;
        }
      }
    }
  }

  @media only screen and (max-width: 959px) {
    .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
      display: flex !important;
    }
  }
}
</style>
