<template>
  <div class="add-link pb-14">
    <v-row>
      <v-col cols="12" md="5" class="text-center">
        <img
          width="60%"
          :src="require('@/assets/images/draw/online-link.png')"
          alt=""
        />
      </v-col>
      <v-col cols="12" md="5">
        <v-form v-model="valid" @submit="nextHandle" ref="addLinkForm">
          <div :class="$vuetify.breakpoint.mobile ? 'text-center' : ''">
            <span class="d-block font-30 font-500 primary--text"
              >{{$t('loremAddLink')}}
            </span>
            <base-input
              class="mt-2 mb-6"
              v-model="link"
              :title="$t('addALink')"
            ></base-input>
              <!-- :title="Add a Link to the desired product" -->
            <v-btn
              class="c-btn mt-10"
              color="secondary"
              min-width="140"
              height="35"
              type="submit"
              :disabled="loadingStepOne"
              ><span class="white--text font-300 font-20" :class="loadingStepOne?'primary--text':'white--text'">{{$t('next')}}
                <v-progress-circular
                v-if="loadingStepOne"
            :size="20"
            :width="2"
            class=""
            color="lightGreyText"
            indeterminate
          ></v-progress-circular>
              </span></v-btn
            >
          </div>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props:{
    loadingStepOne:{
      type:Boolean
    }
  },

  data: () => ({
    link: "",
    valid: false,
  }),
  methods: {
    nextHandle() {
      let valid = this.$refs.addLinkForm.validate();
      if (!valid) return;    
      this.$emit("next", this.link);
    },
  },
};
</script>

<style></style>
